import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak'
import PrivateRoute from '../src/helper/privateroute'
import WebStoryCreatornew from '../src/components/AppContent'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))

function App() {
  return (
    <div className="App">
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{ onLoad: 'login-required' }}
        onTokens={({ token }) => {
          // dispatch(setToken(token));
          localStorage.setItem('token', token)
        }}
      >
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
              <Route
                path="/webstory"
                name="webstory"
                element={
                  <PrivateRoute>
                    <WebStoryCreatornew />
                  </PrivateRoute>
                }
              />
              <Route
                path="/"
                name="webstory"
                element={
                  <PrivateRoute>
                    <WebStoryCreatornew />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Suspense>
        </HashRouter>
      </ReactKeycloakProvider>
    </div>
  )
}

export default App
